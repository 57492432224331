import React from "react";
import { NavLink } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import '../styles/footer.scss';


function FooterGreenStrip(props) {
    return (
        <>
            <div className="bg-white">
                <AnimationOnScroll animateIn="animate__slideInLeft" duration={0.75}>
                    <div className={"green-strip text-center p-2 " + (props.socialIcons ? "" : "mb-8")}>
                        {props.link ?
                            <h2><NavLink to={props.link}>{props.stripText}</NavLink></h2>
                            :
                            <h2>{props.stripText}</h2>
                        }
                    </div>
                </AnimationOnScroll>
            </div>
            { props.socialIcons &&
                <div className="green-strip mb-8 text-center p-2">
                    <div className="social-icons mt-4">
                        <a href="https://www.facebook.com/Cannabis-Bitcoin-105160488738237">
                            <div className="social-icon facebook">
                                <img className="w-6" src="/facebook.png" alt="Facebook" />
                            </div>
                        </a>
                        <a href="https://www.instagram.com/cannabis_bit/">
                            <div className="social-icon instagram">
                                <img className="w-6" src="/instagram.png" alt="Instagram" />
                            </div>
                        </a>
                        <a href="https://www.youtube.com/watch?v=agf2W-FWd88">
                            <div className="social-icon youtube">
                                <img className="w-6" src="/youtube.png" alt="Youtube" />
                            </div>
                        </a>
                    </div>
                </div>
            }
        </>
    );
}

export default FooterGreenStrip;