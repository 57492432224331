import React from "react";
import {Helmet} from "react-helmet";
import {Row, Col} from "antd";
import {Header} from "../components";
import "../styles/global.scss";
import siteTitle from "../constants";

function Pressevideos() {

  const pageTitle="Pressevideos";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>
      <div className="title_of_header">
        <Header title={pageTitle} />
      </div>
    <div className="relative background-container pt-16 pb-24 mb-4">

      <section className="mt-8 py-8 px-4" style={{background: 'none'}}>
        <Row justify="space-around"> 
          <Col xs={24} md={11} className="mb-4 md:mb-0">
            {/* <video controls src="image-video-final.mp4" /> */}
            <iframe title="Video" className="youtube-video" height="360" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F102654109282480%2Fvideos%2F1034327503922407%2F&show_text=false&width=560&t=0" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen></iframe>
          </Col>
          <Col xs={24} md={11}>
            {/* <video controls src="Cannabis-mit-Ton-MP4.mp4" /> */}
            <iframe title="Video" className="youtube-video" height="360" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F102654109282480%2Fvideos%2F459031419514989%2F&show_text=false&width=560&t=0" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen></iframe>
          </Col>
        </Row>
      </section>

    </div>
    </>
  );
}

export default Pressevideos;