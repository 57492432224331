import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Form, Input, Checkbox, Upload } from "antd";
import { AnimationOnScroll } from "react-animation-on-scroll";
import axios from "axios";
import { Header } from "../components";
import "../styles/contact.scss";
import siteTitle from "../constants";
import { UploadOutlined } from "@ant-design/icons";

function Contact() {

  const pageTitle = "Kontakt";
  const [loading, setLoading] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [fileList, setFileList] = useState([]);

  function sendEmail(data) {

    let values = {
      name: data.name,
      email: data.email,
      message: data.nachricht,
    }
    if (fileList && fileList.length > 0) {
      values.attachment = fileList[0];
    }
    setLoading(true);

    setSubmissionError("");
    axios
        .post("https://jointstore.de/admin/api/contact_us", values)
        .then(() => {
          setLoading(false);
          setSubmissionSuccess(true);
        })
        .catch((err) => {
          setSubmissionError(err.response.data.message || err.message);
          setLoading(false);
        })
  }

  return (
      <>
        <Helmet>
          <title>{pageTitle} - {siteTitle}</title>
        </Helmet>
        <div className="title_of_header">
          <Header title={pageTitle} />
        </div>
        <div className="relative contact-content background-container pt-16 pb-24 mb-4 px-4">

          <section className="mt-8" style={{ background: 'none' }}>
            <AnimationOnScroll animateIn="animate__slideInRight" className="p-6 bg-white">
              <div className="form-wrapper py-8 px-8">
                {submissionSuccess ?
                    <>
                      <p className="font-bold text-center">Ihre Nachricht wurde erfolgreich gesendet.</p>
                    </>
                    :
                    <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={sendEmail}>
                      <Form.Item
                          label="Name"
                          name="name"
                          rules={[{ required: true, message: 'Dieses Feld ist erforderlich' }]}
                      >
                        <Input placeholder="Name" />
                      </Form.Item>
                      <Form.Item
                          label="Email"
                          name="email"
                          rules={[{ required: true, message: 'Dieses Feld ist erforderlich' }]}
                      >
                        <Input type="email" placeholder="Email Address" />
                      </Form.Item>
                      <Form.Item
                          label="Anhang"
                          name="anhang"
                      >
                        {/* <Input placeholder="Anhang" /> */}
                        <Upload
                            fileList={fileList}
                            beforeUpload={(file) => {
                              setFileList([file]);
                              return false;
                            }}
                            onRemove={(file) => {
                              setFileList([]);
                            }}
                        >
                          <Button className="btn-upload" icon={<UploadOutlined />}>Upload...</Button>
                        </Upload>
                      </Form.Item>
                      <Form.Item
                          label="Nachricht"
                          name="nachricht"
                          rules={[{ required: true, message: 'Dieses Feld ist erforderlich' }]}
                      >
                        <Input.TextArea placeholder="Ihre Nachricht" style={{ height: '100px' }} />
                      </Form.Item>
                      <Form.Item
                          name="over18"
                          valuePropName="checked"
                          wrapperCol={{ sm: { offset: 6, span: 18 } }}
                          rules={[{
                            required: true,
                            message: 'Dieses Feld ist erforderlich',
                            validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error())
                          }]}
                      >
                        <Checkbox>Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden solange gespeichert, wie sie für den genannten Zweck bzw. gesetzliche Aufbewahrungsfristen benötigt werden. Ich kann die Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.</Checkbox>
                      </Form.Item>
                      <p className="error mt-4 mb-4 text-center">{submissionError}</p>
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button htmlType="submit" className="mx-auto link-btn" disabled={loading}>
                          {loading ? "Bitte warten..." : "Senden"}
                        </Button>
                      </Form.Item>
                    </Form>
                }
              </div>
            </AnimationOnScroll>

            <AnimationOnScroll className="p-6 pt-2 bg-white" animateIn="animate__slideInRight">
              <div className="map-wrapper">
                <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=Hans-Sachs-Stra%C3%9Fe%2017%20%2C%2040721%20Hilden&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="Hans-Sachs-Straße 17 , 40721 Hilden" aria-label="Hans-Sachs-Straße 17 , 40721 Hilden"></iframe>
              </div>
            </AnimationOnScroll>

          </section>



        </div>
      </>
  );
}

export default Contact;