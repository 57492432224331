import React from 'react';
import {Helmet} from 'react-helmet';
import {Header} from '../components';
import '../styles/termine.scss';
import siteTitle from '../constants';
import DataEvents from "../components/Data/DataEvents.json";
import {Card, Space} from 'antd';

function Termine() {
    const pageTitle = 'CANNABIS-MESSE';
    
    return (
        <>
            <Helmet className="heder_termin_title">
                <title>
                    {pageTitle} - {siteTitle}
                </title>
            </Helmet>
            <div className="title_of_header">
                <Header title={pageTitle}/>
            </div>

            <div className="relative termin-content">

                <div className="cards_container ">

                    {
                        DataEvents && DataEvents.map(DataEvent => {
                            return (
                                <div key={DataEvent.id}>
                                    <Space  className="card_styl_header" direction="vertical" size={18}>

                                        <Card className="card_style" title={DataEvent.ConferenceName}
                                              extra={<a href={DataEvent.url} target="_blanck">More</a>}
                                              style={{width: 300}}>
                                            <p><b>Ort:</b> {DataEvent.location}</p>
                                            <p><b>Datum:</b> {DataEvent.date}</p>
                                        </Card>
                                    </Space>

                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>
        </>
    )
}

export default Termine
