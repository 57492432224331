import React from 'react'
import '../styles/bgVideo.scss';
import headerBgVid from '../components/headerBgVid.mp4'

const VideoBgHeder = () => {
  return (
    <div className='video_BG'>
      <video loop autoPlay>
        <source src={headerBgVid} type="video/mp4" />
      </video>
    </div>
  )
}

export default VideoBgHeder
