import React from 'react'
import { createRoot } from 'react-dom/client'
import 'antd/dist/antd.css' // or 'antd/dist/antd.less'
import './index.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Footer } from './components'
import {
  Home,
  About,
  Contact,
  DieWeltimWandel,
  Philosophy,
  NotFound404,
  Presse,
  Logos,
  Pressevideos,
  Animation3D,
  FAQ,
  Impressum,
  Datenschutz,
  AGB,
  Risikoaufklarung,
  CannabisDetails1,
  CannabisDetails2,
  CannabisDetails3,
  UnsereZiele,
  Termine,

} from './pages';
import {HeaderAllWithNavigation} from "./components/HeaderAllWithNavigation";
import Partner from "./pages/Partner";


const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Router>
      <HeaderAllWithNavigation/>
<Routes>

      <Route path="/" element={<Home />} />
      <Route path="/uber-uns" element={<About />} />
      <Route path="/dieWelt-im-Wandel" element={<DieWeltimWandel />} />
      <Route path="/presse" element={<Presse />} />
      <Route path="/presselogos" element={<Logos />} />
      <Route path="/pressevideos" element={<Pressevideos />} />
      <Route path="/3d-animation" element={<Animation3D />} />
      <Route path="/unsere-philosophie" element={<Philosophy />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/kontakt" element={<Contact />} />
      <Route path="/impressum" element={<Impressum />} />
      <Route path="/datenschutz" element={<Datenschutz />} />
      <Route path="/agb-cannabisbit" element={<AGB />} />
      <Route path="/risikoaufklarung" element={<Risikoaufklarung />} />
      <Route path="/cannabis-anlage-iii-btmg" element={<CannabisDetails1 />} />
      <Route path="/cannabis-anlage-i-btmg" element={<CannabisDetails2 />} />
    <Route path="/cannabisbit-Partner" element={<Partner />} />
      <Route
        path="/cannabisbit-kryptowaehrung"
        element={<CannabisDetails3 />}
      />
      <Route path="/unsere-ziele" element={<UnsereZiele />} />
      <Route path="/messe" element={<Termine />} />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
    <Footer />
  </Router>,
)
