import React  from "react";
import {Helmet} from "react-helmet";
import { NavLink } from "react-router-dom";
import {Row, Col} from "antd";
import {Header, FooterGreenStrip} from "../components";
import "../styles/presse.scss";
import siteTitle from "../constants";

function Presse() {

  const pageTitle="Presse";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>
      <div className="title_of_header">
        <Header title={pageTitle} />
      </div>
    <div className="relative presse-content background-container pt-16 pb-24">

      <section className="presse-thumbnails mt-8 pt-8 pb-2 px-4">

        <Row justify="space-around" className="mb-6">
          <Col xs={24} md={8}>
            <div className="card link-card card-blue">
              <div className="mb-4">
                <NavLink to="#">
                  <div className="icon">
                    <img className="icon-img" alt="" src="/presse-icon-news.png" />
                  </div>
                </NavLink>
              </div>
            <NavLink to="#"><h2>Pressemitteilungen</h2></NavLink>
           </div>
          </Col>
          <Col xs={24} md={8}>
            <div className="card link-card card-red">
              <div className="mb-4">
                <NavLink to="/pressevideos">
                  <div className="icon">
                    <img className="icon-img" alt="" src="/presse-icon-video.png" />
                  </div>
                </NavLink>
              </div>
            <NavLink to="/pressevideos"><h2>Pressevideos</h2></NavLink>
           </div>
          </Col>
          <Col xs={24} md={8}>
            <div  className="card link-card card-yellow">
              <div className="mb-4">
                <NavLink to="/presselogos">
                  <div className="icon">
                    <img className="icon-img" alt="" src="/presse-icon-download.png" />
                  </div>
                </NavLink>
              </div>
              <NavLink to="/presselogos"><h2>Logos</h2></NavLink>
            </div>
          </Col>
        </Row>

        <Row justify="space-around">
          <Col xs={24} md={8}>
            <div className="card">
              <a href="/presse-1.jpeg">
                <img src="/presse-1.jpeg" alt="Presse 1" />
              </a>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className="card">
              <a href="/presse-2.jpg">
                <img src="/presse-2.jpg" alt="Presse 2" />
              </a>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className="card">
              <a href="/presse-3.jpeg">
                <img src="/presse-3.jpeg" alt="Presse 3" />
              </a>
            </div>
          </Col>
        </Row>

        <Row justify="space-around">
          <Col xs={24} md={8}>
            <div className="card">
              <a href="/presse-4.jpeg">
                <img src="/presse-4-thumb.jpeg" alt="Presse 4" />
              </a>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className="card">
              <a href="/presse-5.png">
                <img src="/presse-5-thumb.png" alt="Presse 5" />
              </a>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className="card">
              <a href="/presse-6.jpg">
                <img src="/presse-6.jpg" alt="Presse 6" />
              </a>
            </div>
          </Col>
        </Row>

        <Row justify="space-around">
          <Col xs={24} md={8}>
            <div className="card">
              <a href="/presse-7.jpeg">
                <img src="/presse-7-thumb.jpeg" alt="Presse 7" />
              </a>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className="card">
              <a href="/presse-8.jpeg">
                <img src="/presse-8-thumb.jpeg" alt="Presse 8" />
              </a>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className="card">
              <a href="/presse-9.jpg">
                <img src="/presse-9-thumb.jpg" alt="Presse 9" />
              </a>
            </div>
          </Col>
        </Row>

      </section>

    </div>
    <FooterGreenStrip stripText="BESUCHEN SIE UNS AUF" socialIcons={true} />
    </>
  );
}

export default Presse;