import React from 'react';
import { Helmet } from 'react-helmet';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { Header } from '../components';
import '../styles/faq.scss';
import siteTitle from '../constants';

const { Panel } = Collapse

function FAQ() {
  const pageTitle = 'FAQ';

  return (
      <>
        <Helmet>
          <title>
            {pageTitle} - {siteTitle}
          </title>
        </Helmet>
        <div className="title_of_header">
          <Header title={pageTitle} />
        </div>
        <div className="relative faq-content background-container pt-16 pb-24 mb-4 px-4">
          <section className="mt-1">
            <Collapse
                className="faq-section"
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 270 : 0} />
                )}
            >
              <Panel header="WIE KANN ICH MINEN?" key="1">
                <p>
                  1. Legen Sie eine Ethereum-Wallet an, etwa auf der Webseite
                  MyEtherWallet.com.
                </p>

                <p>
                  2. Laden Sie ein Ethereum Mining-Programm auf Ihren PC. Solche
                  Programme sind etwa ETHminer oder Claymore Miner.
                </p>

                <p>
                  3. Nun müssen Sie Ihre Windows-Einstellungen anpassen. Die
                  virtuelle Speichergröße muss mindestens 16.384 MByte betragen.
                  Deaktivieren Sie den Ruhemodus in den
                  Windows-Energieeinstellungen und Windows- Updates aus.{' '}
                </p>
                <p>
                  Nutzen Sie eine Antivirus-Software wie etwa Windows Defender,
                  fügen Sie eine Ausnahme für Ihr Mining-Programm hinzu.
                </p>

                <p>
                  4. Im von Ihnen gewählten Mining-Pool bearbeiten Sie die .bat
                  Datei entsprechend der Anleitung des Mining-Pools.
                </p>

                <p>
                  5. Möchten Sie mit dem Mining beginnen, starten Sie die .bat
                  Datei.
                </p>

                <p>
                  Quelle:{' '}
                  <a href="https://praxistipps.chip.de/ethereum-mining-so-gehts_93139">
                    https://praxistipps.chip.de/ethereum-mining-so-gehts_93139
                  </a>
                </p>
              </Panel>
              <Panel header="WELCHE SICHERHEIT BIETEN WIR?" key="2">
                <p>Sicherheit wird bei der CannabisBIT immer großgeschrieben.</p>
                <p>
                  Unsere Seite ist frei von Viren, die Daten werden auf Servern in
                  Deutschland gespeichert und unterliegen damit der DS-GVO.
                  Bezüglich der Transaktionen und Passwörtern ist der Nutzer
                  selbst für die Auswahl und den Einsatz der Passwörter
                  verantwortlich. Die CannabisBIT kann dafür keine Haftung und
                  Verantwortung übernehmen.{' '}
                </p>
              </Panel>
              <Panel
                  header="WELCHE PRODUKTE KANN ICH MIT CANNABISBIT KAUFEN?"
                  key="3"
              >
                <p>
                  überall, wo der Cannabisbit als Zahlungsmittel akzeptiert wird,
                  kannst Du mit der Währung zahlen.
                </p>
                <p>
                  Momentan ist der Cannabisbit hauptsächlich auf den Cannabis
                  Markt fokussiert. Einfach die Zahlungsmöglichkeiten in Deinem
                  Onlineshop und Store erfragen.
                </p>
              </Panel>

              <Panel
                  header="IST DIE INVESTITION IN DEN CANNABISBIT SICHER?"
                  key="4"
              >
                <p>
                  Kryptowährungen sind Anlagevermögen und Grundlage für
                  Spekulation.
                </p>
                <p>
                  Je mehr Sicherheiten und Kapital das Unternehmen bieten kann,
                  umso sicherer ist der Kurs der Kryptowährung. Natürlich kann es
                  hier zu Schwankungen kommen. Ethereum ist eine der größten und
                  sichersten Blockchain am Markt. 2020 sackte der Kurs ein, konnte
                  sich aber schnell wieder erholen und sogar neue Spitzenwerte
                  erreichen. 2022 erfolgte erneut eine Schwankung. Je mehr Anleger
                  und Kunden den Cannabisbit nutzen, umso sicherer und wertiger
                  wird die Währung. Je vielseitiger die Währung genutzt wird,
                  desto stärker die Akzeptanz. Eine sehr zukunftsorientierte
                  Technologie gepaart mit einem neuen Markt prognostiziert ein
                  Wachstum, stellt aber keine Garantie dar.
                </p>
              </Panel>
              <Panel header="SIND MEINE DATEN SICHER?" key="5">
                <p>
                  Alle Daten unterliegen der DS-GVO, der Datenschutzverordnung.
                </p>
                <p>
                  Eine moderne Serverinfrastruktur bietet die nötige technische
                  Sicherheit. Durch Verfahren wie zum Beispiel KYC, Know Your
                  Customer, der Identifikation durch einen amtlichen Ausweis,
                  erhöhen wir die Sicherheit auf unserer Seite. Natürlich liegt
                  die Verantwortung für persönliche Passwörter beim Kunden und wir
                  bitten darum, diese gut zu sichern. Die CannabisBIT übernimmt
                  keine Haftung für den Verlust von persönlichen
                  Kundenpasswörtern.
                </p>
              </Panel>
              <Panel header="WIE KANN ICH CANNABISBIT KAUFEN?" key="6">
                <p>
                  Du kannst CannabisBit ganz einfach unter buy.cannabis-coin.de erwerben.
                  <div><a href="https://buy.cannabis-coin.com/login">https://cannabisbit.de/</a></div>

                </p>

              </Panel>
            </Collapse>
          </section>
        </div>
      </>
  )
}

export default FAQ
