import React from "react";
import {Helmet} from "react-helmet";
import {Header} from "../components";
import "../styles/global.scss";
import siteTitle from "../constants";

function Animation3D() {

  const pageTitle="3D Animation";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>
    <Header title={pageTitle} />
    <div className="relative background-container pt-16 pb-24 mb-4">

      <section className="mt-8 py-4 px-4">
        <iframe title="Video" className="youtube-video" height="700" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F102654109282480%2Fvideos%2F459031419514989%2F&show_text=false&width=560&t=0" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen></iframe>
      </section>

    </div>
    </>
  );
}

export default Animation3D;