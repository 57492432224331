import React  from "react";
import { NavLink } from "react-router-dom";
import {Helmet} from "react-helmet";
import {Row, Col} from "antd";
import { FastBackwardOutlined } from "@ant-design/icons";
import { AnimationOnScroll } from "react-animation-on-scroll";
import {Header, FooterGreenStrip} from "../components";
import "../styles/global.scss";
import siteTitle from "../constants";

function UnsereZiele() {

  const pageTitle = "Unsere Ziele";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>
      <div className="title_of_header">
        <Header title={pageTitle} />
      </div>


      <div className="relative philosophy-content background-container py-16 mb-8">

      <section className="mt-8 py-8 px-8">
        <Row align="middle"> 
        <Col xs={24} md={12}>
            <img src="/ziele-img-1.jpg" alt="BEZAHLSYSTEM" />
          </Col>
          <Col xs={24} md={12} className="py-8 px-12 mt-12 md:mt-0 md:py-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
              <h2>DIGITALE WÄHRUNG EINFÜHREN</h2>
              <p>Die CannabisBIT GmbH aus Hilden führt eine neue, digitale Währung ein. Der auf Ether basierende Coin soll eingesetzt werden können, um Produkte aus dem Bereich der Hanf- und Cannabisindustrie kaufen zu können.<br/> Die Freigabe von Cannabis zu Genusszwecken ist ein erklärtes Ziel aus dem Koalitionsvertrag der Bundesregierung. Es geht dabei um die Legalisierung von Cannabis zu Genusszwecken mit einem THC-Wert oberhalb von 0,2%. In immer mehr Produkten der Lebensmittelindustrie ist Hanf schon enthalten. Dort liegt der THC-Gehalt jedoch unter dem Schwellenwert von 0,2%. </p>
            </AnimationOnScroll>
          </Col> 
        </Row>
      </section>

      <div className="mt-24 w-full">
        <FooterGreenStrip stripText="LEGALITÄT, OFFENHEIT UND TRANSPARENZ" />
      </div>

      <section className="mt-24 py-8 px-8 mb-8">
        <Row align="middle">
          <Col xs={24} md={12} className="py-8 px-12 mt-12 md:mt-0 md:py-4 md:px-8 ">
            <AnimationOnScroll animateIn="animate__slideInRight">
              <h2>DIGITALE MÜNZE</h2>
              <p>Die CannabisBIT hat es sich zum Ziel gesetzt, diesen Markt mit einem eigenen Token zu unterstützen. Dadurch soll es dem privaten oder geschäftlichen Endverbraucher möglich sein, Cannabisprodukte per digitaler Münze, dem Coin, kaufen zu können. Das ist bisher einzigartig auf dem Markt.</p>
              <p className="mt-3"><strong>Legalität, Offenheit und Transparenz mit der Chance auf Wertsteigerung, sind dabei die erklärten Ziele des Start-Ups.</strong></p>
            </AnimationOnScroll>
          </Col>
          <Col xs={24} md={12}>
            <img src="/ziele-img-2.jpg" alt="KRIMINALITÄT - NEIN, DANKE!" />
          </Col> 
        </Row>
        <NavLink to="/" className="home-link relative top-5 -left-2"><FastBackwardOutlined /> Zurück</NavLink>
      </section>

    </div>
    </>
  );
}

export default UnsereZiele;