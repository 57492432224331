import React, { useEffect, useState } from 'react';
import Navigation from './navigation';
import { Row, Col } from 'antd';
import '../styles/header.scss';


function Header(props) {
  const { isHomePage, title } = props;
  const [isMobile, setIsMobile] = useState(true);

  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < 768);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, [])

  return (
      <header className={'header ' + (isHomePage ? 'home-header' : 'pb-3-5') }>
        <div className={'header-content ' + (isHomePage ? '' : 'pb-28')}>
          {!isMobile}

          <div>

            <Navigation pageTitle={title} home={isHomePage} />

            {/* This is Home Title display */}
            {isHomePage ? (
                <Row justify="center">
                  <Col className="cards_container" >

                  </Col>
                </Row>
            ) : (
                <h1 className="page-title mt-16">{title}</h1>
            )}
          </div>
        </div>
      </header>
  )
}

export default Header
