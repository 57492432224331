import React  from "react";
import {Helmet} from "react-helmet";
import {Row, Col} from "antd";
import { FastBackwardOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import {Header} from "../components";
import "../styles/global.scss";
import siteTitle from "../constants";

function CannabisDetails1() {

  const pageTitle="Cannabis Anlage III, BtMG";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>
      <div className="title_of_header">
        <Header title={pageTitle} />
      </div>

      <div className="relative background-container py-16 mb-8">

      <section className="mt-8 py-8 px-4 p-font-big" style={{maxWidth: '1200px'}}>
        <Row align="middle">
          <Col md={12} className="px-6">
            <img src="/cannabisdetails1-1.jpg" alt="ZERTIFIZIERTE LABORE UND ÄRZTE" />
          </Col>
          <Col md={12} className="py-4 px-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
            <h2>ZERTIFIZIERTE LABORE UND ÄRZTE</h2>
            <p>Diese Form des Cannabis ist schon auf dem Markt und erfreut sich steigender Beliebtheit.</p>
            <p>Daher möchten auch wir der Medizin das Cannabis zur Verfügung stellen, die es wirklich braucht. </p>
            </AnimationOnScroll>
          </Col> 
        </Row>
      </section>

      <section className="mt-16 py-8 px-4 p-font-big" style={{maxWidth: '1200px'}}>
        <Row align="middle">
          <Col md={12} className="py-4 px-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
            <h2>WIR STEHEN FÜR QUALITÄT UND QUANTITÄT</h2>
            <p>Permanente Kontrolle und Überwachung durch zertifizierte Labore und Ärzte sind unser Anspruch für Ihre Versorgung.</p>
            </AnimationOnScroll>
          </Col> 
          <Col md={12} className="px-6">
            <img src="/cannabisdetails1-2.png" alt="WIR STEHEN FÜR QUALITÄT UND QUANTITÄT" />
          </Col>
        </Row>
        <NavLink to="/" className="home-link relative top-5"><FastBackwardOutlined /> Zurück</NavLink>
      </section>

    </div>
    </>
  );
}

export default CannabisDetails1;