import React  from "react";
import {Helmet} from "react-helmet";
import {Row, Col} from "antd";
import { FastBackwardOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import {Header, FooterGreenStrip} from "../components";
import "../styles/global.scss";
import siteTitle from "../constants";

function CannabisDetails2() {

  const pageTitle="Cannabis Anlage I, BtMG";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>

      <div className="title_of_header">
        <Header title={pageTitle} />
      </div>


    <div className="relative background-container py-16">

      <section className="mt-8 py-8 px-4 p-font-big" style={{maxWidth: '1200px'}}>
        <Row align="middle">
          <Col md={12} className="px-6">
            <img src="/cannabisdetails2-1.jpg" alt="CANNABIS, GRAS ODER MARIHUANA" />
          </Col>
          <Col md={12} className="py-4 px-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
            <h2>CANNABIS, GRAS ODER MARIHUANA</h2>
            <p>Cannabis, Gras oder auch Marihuana genannt, mit einem THC-Gehalt von mehr als 0,2%, ist verboten. So steht es im Betäubungsmittelgesetz in der Anlage I. Es gibt jedoch den politischen Willen, Cannabis bis zu einer bestimmten  Menge zum Eigenkonsum und zum Besitz freizugeben. So sieht es die  neue Bundesregierung vor. Bereits vor Jahren haben einzelne Politiker  gefordert, das Hanf freizugeben. Bleibt abzuwarten, wann dieser politische Wille in die Tat umgesetzt werden wird. </p>
            </AnimationOnScroll>
          </Col> 
        </Row>
      </section>

      <section className="mt-16 py-8 px-4 p-font-big" style={{maxWidth: '1200px'}}>
        <Row align="middle">
          <Col md={12} className="py-4 px-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
            <h2>DIE NEUE CANNABISBIT WÄHRUNG</h2>
            <p>Sobald das geschehen ist, wird es mit unserer Kryptowährung möglich sein, Cannabis auch mit der neuen digitalen Währung, dem CannabisBIT zu kaufen und gleichzeitig Gutes zu tun. Mit jeder Transaktion unterstütz der Käufer soziale und umweltfreundliche Projekte in Deutschland, die wir regelmäßig besuchen werden, um darüber zu berichten. Sobald das geschehen ist, wird es mit unserer Kryptowährung möglich sein, CBD-Hanf oder legalisiertes Cannabis sowie alle weiteren Rauchwarenprodukte mit der neuen digitalen Währung, dem  CannabisBIT zu kaufen.</p>
            </AnimationOnScroll>
          </Col> 
          <Col md={12} className="px-6">
            <img src="/cannabisdetails2-2.jpeg" alt="DIE NEUE CANNABISBIT WÄHRUNG" />
          </Col>
        </Row>
      </section>

      <section className="mt-16 py-8 px-4 p-font-big" style={{maxWidth: '1200px'}}>
        <Row align="middle">
          <Col md={12} className="px-6">
            <img src="/cannabisdetails2-3.png" alt="" className="mx-auto" />
          </Col>
          <Col md={12} className="py-4 px-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
            <p> Das ganze wird in unserem neuen Onlineshop www.jointstore.de (im Aufbau) sehr einfach möglich sein. Dort kann dann problemlos mit den Token, aber natürlich auch mit allen anderen Zahlungsmitteln, eingekauft werden. Den Coin wird es auf den meisten gängigen Plattformen zu kaufen geben. Als besonderes „Highlight“ für den Kunden: Wer mit dem Token bezahlt, bekommt mehr von allem: Entweder mehr Ware für den gleichen Preis oderToken als Reinvest wie beim Payback-System ®, was schon heute jeder kennt.</p>
            </AnimationOnScroll>
          </Col> 
        </Row>
        <NavLink to="/" className="home-link relative top-5"><FastBackwardOutlined /> Zurück</NavLink>
      </section>

    </div>
    <FooterGreenStrip stripText="NICHTS IST WICHTIGER ALS TRANSPARENZ. ÜBERALL." />
    </>
  );
}

export default CannabisDetails2;