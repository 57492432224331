import React  from "react";
import {Helmet} from "react-helmet";
import {Row, Col} from "antd";
import {Header, FooterGreenStrip} from "../components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "../styles/about.scss";
import siteTitle from "../constants";


function About() {

  const pageTitle = "Über Uns";

  return (
      <>

        <Helmet>

          <title>{pageTitle} - {siteTitle}</title>
        </Helmet>
        <div className="title_of_header">
          <Header title={pageTitle} />
        </div>
        <div className="relative about-content background-container py-16">

          <section className="about-section-1 mt-2 py-8 px-8">
            <Row align="middle">
              <Col md={12}>
                <img src="/about-1.jpeg" alt="Wir Sind Cannabisbit" />
              </Col>
              <Col md={12} className="py-4 md:px-8">
                <AnimationOnScroll animateIn="animate__slideInRight">
                  <h2>DIE IDEE DES CANNABISBIT</h2>
                  <p>Der Plan der Gründer, die aus unterschiedlichen Branchenzweigen stammen, ist es, Deutschland wirtschaftlich zu stärken und den Menschen den Zugang zu Cannabisprodukten zu gewährleisten, eine Win-Win-Situation sowohl für die Bürger als auch die Regierung.Alles begann 2020, als wir uns, bestehend aus vier Gesellschaftern, einem Investor, einem Spezialisten aus der Finanzbranche, einem Spezialisten aus der Kryptowelt und einem Ideengeber zusammengeschlossen haben. In vielen Meetings und Brainstormings wurde die Idee des Cannabisbit geboren. Eine digitale Währung, die zunächst auf eine Branche beschränkt ist. Diese Branche ist keine beliebige, sondern die aktuelle und zukunftsweisende Branche schlechthin. Mit dem Cannabisbit können die Anleger, Kunden und Interessierten nicht nur bargeldlos zahlen, sie betreiben auch echtes Anlagevermögen. Mit unserer Roadmap könnt Ihr unsere bisherigen Meilensteine nachvollziehen und habt einen Ausblick auf all das, was uns und euch noch erfreuen wird. Der Cannabisbit war der Start vieler Investitionen in unterschiedliche Cannabis- sowie Kryptozweige. Die Kooperation mit dem Jointstore ist ein wichtiger Meilenstein, der geplante Anbau ebenso. Viele weitere Projekte werden Euch auf unserer Kryptoseite vorgestellt, beziehungsweise kommen in Kürze.</p>
                </AnimationOnScroll>
              </Col>
            </Row>
          </section>

          <section className="about-section-2 mt-24 py-8 px-8">
            <Row align="middle">
              <Col md={12} className="py-4 md:px-8">
                <AnimationOnScroll animateIn="animate__slideInRight">
                  <h2>WIR SIND PIONIERE</h2>
                  <p>Die Homepages, die verschiedenen Plattformen und der Token wurden von einem Team von Developern programmiert. Unser SmartContract wurde durch ein Audit geprüft, das Light- und das Whitepaper wurden erstellt. Eins bleibt: wir setzen uns weiterhin voller Engagement dafür ein, dass Cannabis und Krypto in Deutschland etabliert werden. Cannabis ist die natürliche Alternative zu chemischen Präparaten und Krypto ist die Alternative zu klassischer Währung. Werde gemeinsam mit uns zum Pionier. Die Legalisierung von Cannabis (> 0,2% THC) ist noch nicht erfolgt, die wichtigen Schritte sind allerdings schon eingeleitet, so dass die Spezialisten von einer Legalisierung innerhalb der nächsten 24 Monate ausgehen. Diese 24 Monate sind ideal um sich bereits jetzt mit Cannabisprodukten, wie zum Beispiel CBD Produkten, zu beschäftigen. Der Markt in Ländern, die bereits legalisiert haben, Kanada und USA zum Beispiel, sind riesig und der Trend hat sich in den letzten Jahren und Monaten auch hier etabliert. So gibt es bereits jetzt schon dutzende deutsche Händler und Hersteller. Der Cannabisbit ist das Produkt, das den Händlern und Herstellern Lösungen bietet und die Branche vereint und vernetzt..</p>
                </AnimationOnScroll>
              </Col>
              <Col md={12}>
                <img src="/header-bg-static.jpg" alt="Unsere Onlineshop" />
              </Col>
            </Row>
          </section>
          <div className="mt-24 w-full">
            <FooterGreenStrip link="/" stripText="ERFAHRE AUF DIESER SEITE NOCH MEHR ÜBER UNS." />
          </div>
          <section className="about-section-3 mt-24 py-8 px-8">
            <Row align="middle">
              <Col md={12} className="py-4 md:px-8">
                <AnimationOnScroll animateIn="animate__slideInRight">
                  <h2>KRYPTOWÄHRUNG AUF ETHEREUMBASIS</h2>
                  <p>Unser kinderleichtes Bezahlsystem hilft dabei, die Kryptowährung mit dem Cannabiskauf zu verbinden und dabei gleichzeitig Gutes zu tun, denn wir unterstützen mit jeder Transaktion ein Hilfsprojekt in Deutschland.</p>
                  <p className="mt-4">Wir schaffen eine sichere und einfache Kryptowährung auf Ethereumbasis und machen damit den Kauf des CannabisBIT so einfach wie eine Barzahlung.</p>
                </AnimationOnScroll>
              </Col>
              <Col md={12}>
                <img src="/about-3.jpeg" alt="KRYPTOWÄHRUNG AUF ETHEREUMBASIS" />
              </Col>
            </Row>
          </section>


        </div>
        <FooterGreenStrip stripText="WIR SIND PIONIERE! WIR SIND GRÜN!" />

      </>

  );
}

export default About;