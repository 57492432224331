import React  from "react";
import { NavLink } from "react-router-dom";
import {Helmet} from "react-helmet";
import {Row, Col} from "antd";
import { FastBackwardOutlined } from "@ant-design/icons";
import { AnimationOnScroll } from "react-animation-on-scroll";
import {Header, FooterGreenStrip} from "../components";
import "../styles/global.scss";
import siteTitle from "../constants";

function Philosophy() {

  const pageTitle = "Unsere Philosophie";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>
      <div className="title_of_header">
        <Header title={pageTitle} />
      </div>


    <div className="relative philosophy-content background-container py-16 mb-8">

      <section className="mt-8 py-8 px-8 text-center md:text-left">
        <Row align="middle"> 
          <Col xs={24} md={12} className="py-8 px-12 md:py-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
              <h2>BEZAHLSYSTEM</h2>
              <p>Wir sind ein junges Unternehmen für die Vermarktung von Cannabis. Wir sind die Gründer der ersten digitalen Währung für den Kauf von Cannabis zu Genusszwecken. Mit unserer neuen Kryptowährung wird es ganz einfach, Cannabis zu Genusszwecken zu kaufen. Unser Ziel ist es, den neuen CannabisBIT für jedermann zugänglich zu machen. Ein kinderleichtes Bezahlsystem hilft dabei, die Kryptowährung mit dem Cannabiskauf zu verbinden und dabei gleichzeitig Gutes zu tun, denn wir unterstützen mit jeder Transaktion ein Hilfsprojekt in Deutschland. <strong>Ein kinderleichtes Bezahlsystem hilft dabei, die Kryptowährung mit dem Cannabiskauf zu verbinden und dabei gleichzeitig Gutes zu tun</strong>, denn wir unterstützen mit jeder Transaktion ein Hilfsprojekt in Deutschland.</p>
            </AnimationOnScroll>
          </Col>
          <Col xs={24} md={12}>
            <img src="/philosophy-1.jpg" alt="BEZAHLSYSTEM" />
          </Col>
        </Row>
      </section>

      <section className="mt-24 py-8 px-8 text-center md:text-left">
        <Row align="middle">
          <Col xs={24} md={12}>
            <img src="/philosophy-2.jpeg" alt="MEDIKAMENTE AUF CANNABISBASIS" />
          </Col> 
          <Col xs={24} md={12} className="py-8 px-12 mt-12 md:mt-0 md:py-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
              <h2>MEDIKAMENTE AUF CANNABISBASIS</h2>
              <p>Wir schaffen eine sichere und einfache Kryptowährung und machen damit den CannabisBIT so einfach wie eine Bargeldzahlung. Auch wollen wir den Anbau, den Verkauf und den Einsatz von Cannabis betreiben. Herkömmlicher und medizinischer Cannabis kommen dabei zum Einsatz.<br/>
              <strong>Cannabis und seine Wirkstoffe sind eine echte und uralte Alternative zu Medikamenten auf Chemiebasis. Der medizinische findet schon heute in immer mehr Produkten und bei immer mehr medizinischen Behandlungen seinen Einsatz, doch die Verwendung von Cannabis, Hanf oder Marihuana nicht neu.</strong></p>
            </AnimationOnScroll>
          </Col>  
        </Row>
      </section>

      <section className="mt-24 py-16 px-8 text-center md:text-left">
        <Row align="middle">
          <Col xs={24} md={12} className="text-center">
            <img className="mx-auto" src="/philosophy-3.gif" alt="VERGANGENHEIT. GEGENWART. ZUKUNFT." />
          </Col> 
          <Col xs={24} md={12} className="py-8 px-12 mt-12 md:mt-0 md:py-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
              <h2>VERGANGENHEIT. GEGENWART. ZUKUNFT.</h2>
              <p>Bereits vor über 1.000 Jahren wurde beispielsweise hier in Deutschland schon über die heilende Wirkung von Cannabis berichtet und daran geforscht.Wir greifen diese Forschung aus der Vergangenheitauf, kombinieren sie mit den Einsatz- und Bezahlmöglichkeiten der Gegenwart und sind somit der Garant für eine gute und grüne Zukunft.<br/>
              <strong>Apropos Grün: Unseren Strom beziehen wir jetzt schon aus vollständig CO2-neutralem Strom!</strong> Wir wollen mit dem neuen CannabisBIT von Beginn an Transparenz und Offenheit schaffen. Es soll ein vollumfängliches Kauf- und Genusserlebnis werden. Mit Cannabis. Mit dem Coin.</p>
            </AnimationOnScroll>
          </Col>  
        </Row>
      </section>

      <div className="mt-24 w-full">
        <FooterGreenStrip stripText="EINFACH. DIGITAL. GENIESSEN." />
      </div>

      <section className="mt-24 py-8 px-8 mb-8">
        <Row align="middle">
          <Col xs={24} md={8}>
            <img src="/philosophy-4.jpeg" alt="KRIMINALITÄT - NEIN, DANKE!" />
          </Col> 
          <Col xs={24} md={8} className="py-16 md:py-4 md:px-12">
            <AnimationOnScroll animateIn="animate__slideInRight">
              <h2>KRIMINALITÄT - NEIN, DANKE!</h2>
              <p>Eines steht dabei jetzt und in Zukunft schon fest: <strong>Kriminalität - Nein Danke!</strong><br/>
              Wir halten alle aktuellen Gesetze ein und stehen von Beginn an mit der Bundesopiumstelle in engem Austausch, denn Vertrauen beginnt auf allen Seiten am Anfang eines Vorhabens. Erfahre noch mehr über uns und unserer Idee und hilf dabei, sie zum Leben zu erwecken.</p>
            </AnimationOnScroll>
          </Col>
          <Col xs={24} md={8}>
            <img src="/philosophy-5.png" alt="KRIMINALITÄT - NEIN, DANKE!" />
          </Col> 
        </Row>
        <NavLink to="/" className="home-link relative top-5 -left-2"><FastBackwardOutlined /> Zurück</NavLink>
      </section>

    </div>
    </>
  );
}

export default Philosophy;