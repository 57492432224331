import React  from 'react';
import { Row, Col, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Header, FooterGreenStrip } from '../components';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../styles/home.scss';
import siteTitle from '../constants';

/* Alice Carousel Configuration */
const handleDragStart = (e) => e.preventDefault()

const carouselItems = [
  <img
      alt=""
      src="/home-carousel-1.jpg"
      onDragStart={handleDragStart}
      role="presentation"
  />,
  <img
      alt=""
      src="/home-carousel-2.jpeg"
      onDragStart={handleDragStart}
      role="presentation"
  />,
  <img
      alt=""
      src="/home-carousel-3.jpeg"
      onDragStart={handleDragStart}
      role="presentation"
  />,
  <img
      alt=""
      src="/home-carousel-4.jpeg"
      onDragStart={handleDragStart}
      role="presentation"
  />,
  <img
      alt=""
      src="/home-carousel-5.jpeg"
      onDragStart={handleDragStart}
      role="presentation"
  />,
  <img
      alt=""
      src="/home-carousel-6.jpg"
      onDragStart={handleDragStart}
      role="presentation"
  />,
  <img
      alt=""
      src="/home-carousel-7.jpg"
      onDragStart={handleDragStart}
      role="presentation"
  />,
  <img
      alt=""
      src="/home-carousel-8.jpg"
      onDragStart={handleDragStart}
      role="presentation"
  />,
];

const carouselBreakpoints = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

function Home() {
const pageTitle='We are the Future'
  return (
      <>
        <script
            type="text/javascript"
            async
            src="https://play.vidyard.com/embed/v4.js"
        ></script>
        <Helmet>
          <title>{siteTitle} - {pageTitle}</title>
        </Helmet>
        <Header isHomePage={true} />

        <div className="home-content">
          <section className="home-cards py-8">
            <Row justify="space-evenly">
              <Col xs={15} sm={12} lg={5}>
                <AnimationOnScroll
                    animateIn="animate__slideInLeft"
                    className="mb-8 lg:mb-0 card"
                >
                  <NavLink to="/cannabis-anlage-iii-btmg">
                    <img
                        className="card-img mx-auto mb-8"
                        src="/card-healthcare.png"
                        alt="/card-healthcare.png"
                    />
                    <h2 className="card-caption">CANNABIS ALS MEDIZIN</h2>
                  </NavLink>
                  <p className='card_description'>Strenge Kontrolle.</p>
                  <NavLink to="/cannabis-anlage-iii-btmg">
                    <Button className="link-btn mx-auto">Mehr Lesen</Button>
                  </NavLink>
                </AnimationOnScroll>
              </Col>


              <Col xs={15} sm={12} lg={5}>
                <AnimationOnScroll
                    animateIn="animate__slideInUp"
                    className="mb-8 lg:mb-0 card"
                >
                  <NavLink to="/cannabis-anlage-1-btmg">
                    <img
                        className="card-img mx-auto mb-8"
                        src="/card-start.png"
                        alt="Start"
                    />
                    <h2 className="card-caption">CANNABIS ALS GENUSSMITTEL</h2>
                  </NavLink>
                  <p className='card_description'>Gesetzliche Vorgaben.</p>
                  <NavLink to="/cannabis-anlage-i-btmg">
                    <Button className="link-btn mx-auto">Mehr Lesen</Button>
                  </NavLink>
                </AnimationOnScroll>
              </Col>

              <Col xs={15} sm={12} lg={5}>
                <AnimationOnScroll
                    animateIn="animate__slideInRight"
                    className="mb-8 lg:mb-0 card"
                >
                  <NavLink to="/cannabisbit-kryptowaehrung">
                    <img
                        className="card-img mx-auto mb-8"
                        src="/presselogos/logo-orginal.jpg"
                        alt="Cannabisbit Logo"
                    />
                    <h2 className="card-caption">CANNABIS ALS WÄHRUNG</h2>
                  </NavLink>
                  <p className='card_description'>Zahlungsmittel der Zukunft.</p>
                  <NavLink to="/cannabisbit-kryptowaehrung">
                    <Button className="link-btn mx-auto">Mehr Lesen</Button>
                  </NavLink>
                </AnimationOnScroll>
              </Col>
            </Row>
          </section>

          <div className="background-container py-16 px-2">
            <section className="unsere-philosophie mt-8 pt-8 px-8 pb-13">
              <Row>
                <Col  lg={8}>
                  <img src="/hanfblatt-3.png" alt="Hanfblatt 3" />
                </Col>
                <Col lg={8} className="p-8">
                  <AnimationOnScroll animateIn="animate__slideInRight">
                    <h2>Unsere Philosophie</h2>
                    <p>
                      Tranzsparenz. Ständige Kontrolle.
                      <br />
                      Ständige Überwachung.Alles direkt vor Ort.Mit unserer neuen
                      Kryptowährung wird es ganz einfach, Cannabis zu
                      Genusszwecken zu kaufen.
                      <br />
                      Lesen Sie auf dieser Seite mehr über uns und unsere
                      Philosophie.
                    </p>
                    <NavLink to="/unsere-philosophie">
                      <Button className="link-btn mt-6">Mehr Lesen</Button>
                    </NavLink>
                  </AnimationOnScroll>
                </Col>
                <Col lg={8}>
                  <img src="/hanfblatt-2.png" alt="Hanfblatt 2" />
                </Col>
              </Row>
            </section>

            <section className="unsere-ziele mt-17 pt-8 px-8 pb-8">
              <Row>
                <Col lg={8} className="p-8">
                  <AnimationOnScroll animateIn="animate__slideInRight">
                    <h2>Unsere Ziele</h2>
                    <p>
                      Neue Erkenntnisse auf dem Gebiet von Medizinalcannabis
                      leichter zugänglich zu machen und praxisorientiert
                      darzustellen. Nach der Freigabe von Cannabis zu
                      Genusszwecken, werden wir den Markt mit hochwertigen
                      Produkten versorgen. Dabei sind Qualität und Verbindlichkeit
                      die Grundfesten unserer Arbeit.
                    </p>
                    <NavLink to="/unsere-ziele">
                      <Button className="link-btn mt-6">Mehr Lesen</Button>
                    </NavLink>
                  </AnimationOnScroll>
                </Col>
                <Col lg={8}>
                  <img src="/hanfblatt-1.png" alt="Hanfblatt 1" />
                </Col>
                <Col lg={8} className="p-8">
                  <AnimationOnScroll animateIn="animate__slideInRight">
                    <h2>UNSERE ZUKUNFTSWÄHRUNG</h2>
                    <p>
                      Wir machen Kryptowährung einfach. Kryptowährung ist nicht
                      ganz neu, aber auch nicht ganz transparent. Das wollen wir
                      ändern. Mit uns, unseren Shops und unserer Währung wird es
                      einfach, Kryptowährung zu verstehen, zu nutzen und zu
                      bezahlen. So wie heute Bargeld oder EC-Zahlungen.
                    </p>
                    <NavLink to="/cannabisbit-kryptowaehrung">
                      <Button className="link-btn mt-6">Mehr Lesen</Button>
                    </NavLink>
                  </AnimationOnScroll>
                </Col>
              </Row>
            </section>

            <section className="video-section mt-11 p-4">
               <iframe
                  title="Video"
                  className="youtube-video"
                  height="700"
                  src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F102654109282480%2Fvideos%2F1034327503922407%2F&show_text=false&width=560&t=0"
                  frameborder="0"
                  allow="fullscreen; picture-in-picture"
                  allowfullscreen
              ></iframe>

            </section>

            <section className="carousel-section mt-24 pt-8 pb-0 px-6">
              <AliceCarousel
                  mouseTracking
                  disableButtonsControls
                  autoPlay
                  autoPlayInterval={3000}
                  items={carouselItems}
                  responsive={carouselBreakpoints}
                  controlsStrategy="alternate"
              />
            </section>
          </div>
        </div>
        <FooterGreenStrip stripText="FOLGEN SIE UNS" socialIcons />
      </>
  )
}

export default Home
