import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components';
import '../styles/HeaderAllWithNavigation.scss';
import siteTitle from '../constants';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CannabisBitPartnerData from "../components/Data/CannabisBitPartnerData.json";

function Partner() {
  const pageTitle = 'PARTNER';

  return (
    <>
      <Helmet className="heder_termin_title">
        <title>
          {pageTitle} - {siteTitle}
        </title>
      </Helmet>
        <div className="title_of_header">
            <Header title={pageTitle} />
        </div>

        <div className="relative contact-content pb-24 mb-4 px-4">

        <div className="cards_container ">

            {
                CannabisBitPartnerData&&CannabisBitPartnerData.map(partner=>{
                  return(
                      <div  key={partner.id}>
                          <Card style={{ width: '18rem' }}>
                              <Card.Img style={{ width: '75%',height:"100px"  }} variant="top" src={partner.img} />
                              <Card.Body>
                                  <Card.Title className="cards">
                                      <b>{partner.title}</b>
                                  </Card.Title>
                                  <Card.Text>
                                      {partner.description}
                                  </Card.Text>

                                  <Button
                                      href={partner.url}
                                      variant="primary"
                                      target="=_blanck"
                                  >
                                    Link
                                  </Button>
                              </Card.Body>
                          </Card>

                      </div>
                  )
                })
            }

        </div>
      </div>
    </>
  )
}

export default Partner;
