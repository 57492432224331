import React  from 'react';
import { Helmet } from 'react-helmet';
import { Col } from 'antd';
import { Header } from '../components';
import '../styles/DieWeltimWandel.scss';
import siteTitle from '../constants';
import Button from 'react-bootstrap/Button';

function DieWeltimWandel() {
  const pageTitle = 'Die Welt im Wandel'

  return (
    <>
      <Helmet>
        <title>
          {pageTitle} - {siteTitle}
        </title>
      </Helmet>

      <div className="title_of_header">
        <Header title={pageTitle} />
      </div>


      <div className="conatain_all  ">
        <section className="mt-8 py-8 px-4">
          <Col md={28} className="py-4 md:px-4">
     {/*       <h3>DIE WELT IM WANDEL</h3>*/}

            <h3>
              CannabisBIT ist die Branchenwährung für die deutsche
              Cannabisindustrie und noch viel mehr.
            </h3>
            <p>
              Während sich die Welt momentan im Wandel von klassischer Währung
              auf Kryptowährung befindet, macht Deutschland gleich mehrere
              Wandel durch. Kryptowährungen verbreiten sich und die
              Cannabislegalisierung kommt. Wir bieten Dir die Transparenz und
              die Sicherheit, um von Anfang an in die aktuell zwei wichtigsten
              Märkte zu investieren. Die Cannabisindustrie in Deutschland ist
              erst vor wenigen Jahren gestartet und mit der anstehenden
              Legalisierung stellen sich bereits jetzt eine Vielzahl von
              Unternehmen auf, die in dieser Branche Fuß fassen möchten. Wir
              unterstützen mit unserer Währung jeden Händler und somit die
              gesamte Branche.
            </p>
            <h3>Werde mit uns zum Pionier.</h3>
          </Col>
          <Button
            className="btn btn-success "
            href="https://buy.cannabis-coin.com/login"
          >
            Buy Token
          </Button>

          <p>
            Die Idee einer Kryptowährung ist nicht neu. Doch wir verbinden
            gleich zwei Welten und erschaffen dadurch ein Novum. Eine
            Kryptowährung für eine gesamte Branche. Eine Branche, die neu
            entsteht und viele Neuerungen und Innovationen mit sich bringt. Vom
            Anbau, über die Logistik, den Vertrieb und die Aufklärung über den
            Konsum, wir sind in allen Bereichen der Cannabisindustrie direkt
            vertreten. Wir sind ihr direkter Ansprechpartner.
          </p>

          <h3>
            Besuche uns, mache Dir ein eigenes Bild, werde ein Teil der
            Cannabisbit GmbH und arbeite mit uns.
          </h3>
          <Button className="btn btn-success" href="https://cannabisbit.de/">
            CannabisBIT
          </Button>
          <h3>Hier erhältst Du die weltweit angesagtesten Cannabis Produkte</h3>
          <p>
            Der erste Kooperationspartner der Cannabisbit ist der Onlinehändler
            Jointstore
          </p>
          <Button
            className="btn btn-success"
            href="https://www.jointstore.de./"
          >
            JointStore
          </Button>
          <p>
            Hier erhältst Du die weltweit angesagtesten Cannabis Produkte.
            Weitere Kooperationen aus den unterschiedlichsten Branchen
            Deutschlands werden in Kürze veröffentlicht.
          </p>
          <Button
            className="btn btn-success"
            href="https://www.jointstore.de./"
          >
            Erfahre mehr über unserem Shop
          </Button>
          <p>
            Unser Ziel ist es, Menschen über Cannabis aufzuklären, ob für
            medizinische Zwecke oder Genusszwecke.
          </p>
          <Button
            className="btn btn-success"
            href="https://cannabisbit.de/unsere-ziele"
          >
            Hier erfährst Du mehr...
          </Button>
          <p>
            Die Hauptidee hinter CannabisBIT Token ist die Bereitstellung eines
            dezentralisierten Transaktionsnetzwerks, das auf der
            Ethereum-Blockchain (ERC-20) und der Binance Smart Chain (BEP-20)
            arbeitet. Marktschwankungen bestimmen den weiteren Weg für
            CannabisBIT Token, aber das Modell, auf dem es basiert,
            gewährleistet langfristige Nachhaltigkeit.
          </p>
          <p>
            Daher haben wir uns entschieden, über ICO den Anbau zu finanzieren
            und dank des CannabisBIT-Coins die Zahlungskosten zu senken und die
            Branchenteilnehmer unabhängiger von Zahlungsdienstleistern zu
            machen. Wir gründen Headshops, Fanshops und verwalten eine
            Community, in der wir uns für verschiedene Veranstaltungen wie
            Messen, Festivals, Flagships in Düsseldorf usw. engagieren. Sei von
            Anfang an dabei.
          </p>
          <Button
            className="btn btn-success"
            href="https://buy.cannabis-coin.com/login"
          >
            Investiere! Join the Coin
          </Button>
          <p>Join the Coin, werde Teil der Cannabisbit Community.</p>
          <Button className="btn btn-success" href="https://cannabisbit.de/">
            CannabisBIT
          </Button>

          <h3>Was ist Cannabis?</h3>
          <p>
            Wußtest Du, dass ein Hektar Hanf so viel Sauerstoff produziert wie
            25 Hektar Wald?
          </p>
          <p>
            Dabei wachsen Cannabispflanzen innerhalb von 4 Monaten und Bäume
            brauchen Jahre.
          </p>
          <p>
            Wußtest Du, dass Cannabispflanzen Teil vieler Studien renommierter
            Universitäten sind, um endlich eine Lösung für Krankheiten zu
            finden, die bisher als unheilbar galten?
          </p>

          <p>
            Wußtest Du, dass die erste Jeans 1870 aus Hanf war und dass es sogar
            ein Hanf Auto gab?
          </p>

          <p>
            Cannabis wird bereits seit Jahrtausenden unter anderem als
            Dämmmaterial, Nahrung, Medizin und Kleidung genutzt. Die
            berauschende Wirkung von Cannabis ist nur ein kleines Spektrum
            dieser Pflanze. Cannabis stammt ursprünglich aus Zentralasien und
            konnte sich aufgrund seiner Flora schnell über die gesamte Welt und
            alle Klimazonen ausdehnen. Cannabis wurde in nahezu allen
            Hochkulturen genutzt. Die berühmte Friedenspfeife der indigenen
            Völker Amerikas war unter anderem mit Cannabis angereichert. Im
            Mittelalter wurde es für das Militä r aber auch für das Volk
            genutzt. Später erkannte man den Nutzen in der Medizin und bei
            vielen Krankheiten, wie zum Beispiel der Pest, war Cannabis ein
            Bestandteil der Behandlung.
          </p>

          <p>
            Heute, nach den Restriktionen des 20. Jahrhunderts, entdeckt die
            Medizin Cannabis wieder. So werden Abhängigkeit,
            Aufmerksamkeits-Defizit-Hyperaktivitätsstörung, Angststörungen,
            Appetitlosigkeit, Arthritis, Asthma, Autoimmunerkrankungen,
            Bewegungsstörung, chronische Darmerkrankung, Demenz, Depression,
            Entzündungen, Epilepsie, Glaukome, Kachexie, Krebs, Morbus Chron,
            Multiples Sklerose, Psychosen, posttraumatische Belastungsstörungen,
            Rheuma, Schizophrenie, Schlafstörungen, Spastik und das
            Tourette-Syndrom mit Cannabis behandelt. Natürlich ersetzt der
            Konsum von Cannabis, auch wenn es hochprofessionell entwickelt und
            angeboten wird, nicht den Besuch eines Arztes.
          </p>
          <p>
            Generell ist die Cannabispflanze noch nicht gänzlich erforscht. Man
            hat bisher nur wenige der mindestens 113 Cannabinoide isoliert und
            erforscht. So sind die bekanntesten THC (Tetrahydrocannabinol) und
            CBD (Cannabidiol). THC ist bekannt durch seine berauschende Wirkung
            und ist somit der noch verbotene Stoff in Deutschland. Aktuell
            dürfen Cannabisprodukte einen THC Wert von 0,2% nicht überschreiten.
            CBD hingegen ist nicht verboten und ist bekannt für seine
            beruhigende und gesundheitsfördernde
          </p>

          <h3>Wirkung:</h3>
          <p>
            CBD macht circa 40% des Pflanzenextraktes einer Cannabispflanze aus.
          </p>
          <p>
            Unabhängig der Cannabinoide sind Hanfsamen eine optimale
            Nahrungsergänzung. In unterschiedlichen Studien konnte nachgewiesen
            werden, dass die Samen 20-30% Kohlenhydrate, Proteine, sowie
            wichtige öle, Spurenelemente, Mineralstoffe und Vitamine enthalten.
          </p>
          <p>
            Die aktuelle Bundesregierung hat im Koalitionsvertrag die
            Legalisierung von Cannabis aufgenommen. Bereits jetzt gibt es eine
            Vielzahl von Produkten, meist auf CBD Basis, im Handel. Wir gehen
            davon aus, dass der Konsum von THC haltigem Cannabis in 2023, jedoch
            spätestens 2024 legalisiert wird.
          </p>
        </section>
      </div>
    </>
  )
}

export default DieWeltimWandel
