import React from "react";
import {Helmet} from "react-helmet";
import {Header} from "../components";
import "../styles/global.scss";
import siteTitle from "../constants";

function Impressum() {

  const pageTitle="Impressum";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>
    <Header title={pageTitle} />
    <div className="relative background-container py-16 mb-8 px-4 lg:px-12">

      <section className="mt-8 py-8 px-4 p-font-big">
        <h2>Impressum</h2>

        <p>Haftung für Inhalte Angaben nach §5 TMG:</p>

        <p>CannabisBIT GmbH<br />
        Hans-Sachs-Str. 17<br />
        D-40721 Hilden<br />
        Tel:0152/34606682 </p>

        <p><a href="mailto:info@cannabisbit.de">info@cannabisbit.de</a><br />
        <a href="https://www.cannabisbit.de">www.cannabisbit.de</a></p>

        <p>Registergericht: Düsseldorf<br />
        Registriernummer: HRB 96736<br />
        Vertretungsberechtigter Geschäftsführer: Alexander Junker<br />
        Datenschutzbeauftragter: Michael Schleret-Köhler<br />
        Verantwortlich für den Inhalt der Website: CannabisBIT GmbH<br />
        Umsatzsteuer-Id.: 135/5711/1988 Finanzamt Hilden<br />
        Bild-/Videoquellen: Eigenproduktion oder Adobe-Stock (erweiterte Lizenzen)</p>

        <p><strong>Rechtliche Hinweise zur Webseite</strong></p>

        <p><strong>Haftung für Inhalte</strong></p>

        <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach § 8 bis §10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei bekannt werden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>

        <p><strong>Haftung für Links</strong></p>

        <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei bekannt werden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>

        <p><strong>Urheberrecht</strong></p>

        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Die Betreiber der Seiten sind bemüht, stets die Urheberrechte anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen.</p>

        <p><strong>Datenschutz</strong></p>

        <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen.<br />
        Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.<br />
        Information zur Online-Streitbeilegung gemäß Art. 14 § 1 ODR-VO (Online-Streibeilegungs-Verordnung):<br />
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden: (http://ec.europa.eu/consumers/odr. Verbraucher haben die Möglichkeit, diese Plattform für die Beilegung von Streitigkeiten über online getätigte Käufe oder abgeschlossene Dienstleistungsverträge zu nutzen.</p>
      </section>

    </div>
    </>
  );
}

export default Impressum;