
function HeaderbgVid(){
    return (
        <div>
            <video autoPlay loop muted>
                <source src="/header-bg-vid"/>
            </video>
        </div>
    )

}
export default HeaderbgVid;