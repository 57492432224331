import React from "react";
import {Helmet} from "react-helmet";
import {Row, Col, Button} from "antd";
import {Header} from "../components";
import "../styles/logos.scss";
import siteTitle from "../constants";

function Logos() {

  const pageTitle="Firmenlogos";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>
    <Header title={pageTitle} />
    <div className="relative logos-content background-container pt-16 pb-24 mb-4">
      <section className="mt-8 py-8 px-4" style={{maxWidth: '1232px'}}>
        <Row justify="space-around">
          <Col xs={22} md={9} className="logo-section py-8 px-4 mb-8 md:mb-0">
            <img className="logo mx-auto" src="/logo-1.png" alt="Logo 1" />
            <table className="links-table mx-auto mt-6">
              <tr>
                <td><a href="/presselogos/jpg/logo-1.jpg" download>Farbige Münze.jpg</a></td>
                <td><a href="/presselogos/jpg/logo-1.jpg" download><Button className="link-btn">Herunterladen</Button></a></td>
              </tr>
              <tr>
                <td><a href="/presselogos/png/logo-1.png" download>Farbige Münze.png</a></td>
                <td><a href="/presselogos/png/logo-1.png" download><Button className="link-btn">Herunterladen</Button></a></td>
              </tr>
              <tr>
                <td><a href="/presselogos/svg/logo-1.svg" download>Farbige Münze.svg</a></td>
                <td><a href="/presselogos/svg/logo-1.svg" download><Button className="link-btn">Herunterladen</Button></a></td>
              </tr>
              <tr>
                <td><a href="/presselogos/tif/logo-1.tif" download>Farbige Münze.tif</a></td>
                <td><a href="/presselogos/tif/logo-1.tif" download><Button className="link-btn">Herunterladen</Button></a></td>
              </tr>
            </table>
          </Col>
          <Col xs={22} md={9} className="logo-section py-8 px-4">
            <img className="logo mx-auto" src="/logo-2.png" alt="Logo 2" />
            <table className="links-table mx-auto mt-6">
              <tr>
                <td><a href="/presselogos/jpg/logo-2.jpg" download>Farbige Münze.jpg</a></td>
                <td><a href="/presselogos/jpg/logo-2.jpg" download><Button className="link-btn">Herunterladen</Button></a></td>
              </tr>
              <tr>
                <td><a href="/presselogos/png/logo-2.png" download>Farbige Münze.png</a></td>
                <td><a href="/presselogos/png/logo-2.png" download><Button className="link-btn">Herunterladen</Button></a></td>
              </tr>
              <tr>
                <td><a href="/presselogos/svg/logo-2.svg" download>Farbige Münze.svg</a></td>
                <td><a href="/presselogos/svg/logo-2.svg" download><Button className="link-btn">Herunterladen</Button></a></td>
              </tr>
              <tr>
                <td><a href="/presselogos/tif/logo-2.tif" download>Farbige Münze.tif</a></td>
                <td><a href="/presselogos/tif/logo-2.tif" download><Button className="link-btn">Herunterladen</Button></a></td>
              </tr>
            </table>
          </Col>
        </Row>
      </section>

    </div>
    </>
  );
}

export default Logos;