import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import '../styles/footer.scss';


function Footer() {

  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.scrollY > 50) {
        setShowScrollTopBtn(true);
      } else {
        setShowScrollTopBtn(false);
      }
    });
  }, []);

  return (
    <>
      <footer className="footer_container">
        <div className="footer-navigation py-6 flex justify-center">
          <div className="px-8" style={{ maxWidth: '950px', width: '100%' }}>
            <Row>
              <Col xs={24} md={8} className="mb-4 md:mb-0">
                <ul className="footer-nav-links">
                  <li className="footer_title">NAVIGATION</li>
                  <li><NavLink className="nav-link" to="/">Startseite</NavLink></li>
                  <li><NavLink className="nav-link" to="/uber-uns">Über uns</NavLink></li>
                  <li><NavLink className="nav-link" to="/einfuhrung">Einführung</NavLink></li>
                  <li><NavLink className="nav-link" to="/faq">FAQ</NavLink></li>
                </ul>
              </Col>
              <Col xs={24} md={8} className="mb-4 md:mb-0">
                <ul className="footer-nav-links">
                  <li className="footer_title">INFORMATION</li>
                  <li><NavLink className="nav-link" to="/impressum">Impressum</NavLink></li>
                  <li><NavLink className="nav-link" to="/datenschutz">Datenschutz</NavLink></li>
                  <li><NavLink className="nav-link" to="/agb-cannabisbit">AGB</NavLink></li>
                  <li><NavLink className="nav-link" to="/unsere-philosophie">Unsere Philosophie</NavLink></li>
                  <li><NavLink className="nav-link" to="/risikoaufklarung">Risikoaufklärung</NavLink></li>
                </ul>
              </Col>
              <Col xs={24} md={8} className="mb-4 md:mb-0">
                <p className="footer_title">KONTAKT</p>
                <p>Registriernummer: HRB 96736</p>
                <p>Registergericht: Düsseldorf</p>
                <p>USt-IdNr.: 135/5711/1988 Finanzamt Hilden</p>
                <p>Vertretungsberechtigter Geschäftsführer:</p>
                <p>Alexander Junker</p>
              </Col>
            </Row>
          </div>
        </div>
      </footer>
      {showScrollTopBtn &&
        <div className="back-to-top-btn" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <img alt="" src="/back-to-top.png" />
        </div>
      }
    </>
  );
}

export default Footer;