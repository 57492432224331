import '../styles/HeaderAllWithNavigation.scss'
import React, {useState, useEffect} from "react";
import VideoBgHeder from "./VideoBgHeder";


export const HeaderAllWithNavigation = (props) => {
    const [active, setActive] = useState("nav_menu")
    const [toggleIcon, setToggleIcon] = useState("nav_toggler")
    const [homeText, setHomeText] = useState('')
    const [isMobeilTitle, setIsMobeilTitle] = useState(true)
    const {isHomePage} = props;
    const [isMobile, setIsMobile] = useState(true)


    const navToggle = () => {
        if (active === "nav_menu") {
            setActive("nav_menu nav_active");
        } else {
            setActive("nav_menu");
        }
        
        if (toggleIcon === "nav_toggler") {
            setToggleIcon('nav_toggler toggle');
        } else {
            setToggleIcon('nav_toggler');
        }
    }
    
    const checkIsMobile = () => {
        const isDesktopView = window.innerWidth >= 768;
        setIsMobile(isDesktopView);
        setHomeText(isDesktopView);
        setIsMobeilTitle(!isDesktopView);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', checkIsMobile);
        return () => window.removeEventListener('resize', checkIsMobile);
        setHomeText();
        setIsMobeilTitle(true);
        setIsMobeilTitle(false);
    }, [])


    return (
        <header className={'header ' + (isHomePage ? 'home-header' : '')}>
            <div className={'header-content ' + (isHomePage ? '' : 'pb-28')}>
                {!isMobile}
                <div className="Header_All_container">

                    <div className="nav_container">
                        <a href='/' className="nav_brand"><img src='/presselogos/logo-orginal.jpg'/></a>
                        <ul className={active}>
                            <li className="nav_item"><a href="/uber-uns">ÜBER UNS</a></li>
                            <li className="nav_item"><a href="/dieWelt-im-Wandel">DIE WELT IM WANDEL</a></li>
                            <li className="nav_item"><a href="https://buy.cannabis-coin.com/login" target="_blank">BUY
                                TOKEN</a>
                            </li>
                            <li className="nav_item"><a href="https://www.jointstore.de/" target="_blank"> JOINTSTORE</a></li>
                            <li className="nav_item"><a href="/presse">PRESSE</a></li>
                            <li className="nav_item"><a href="/messe">MESSE</a></li>
                            <li className="nav_item"><a href="/faq">FAQ</a></li>
                            <li className="nav_item"><a href="/kontakt"> KONTAKT</a></li>
                            <li className="nav_item"><a href="/cannabisbit-Partner"> PARTNER</a></li>
                        </ul>

                        <div onClick={navToggle} className={toggleIcon}>
                            <div className="line1"></div>
                            <div className="line2"></div>
                            <div className="line3"></div>
                        </div>
                    </div>

                    <div className="videoBG_header">
                        <VideoBgHeder/>
                        <div className="home-text_container">
                            <h1 className="text-light_home_contain">
                                <strong>CANNABIS</strong> IST
                                <br/>
                                REVOLUTION.
                                <br/>
                                <strong>KRYPTOWÄHRUNG</strong>
                                <br/>
                                IST REVOLUTION.
                                <br/>
                                <strong>CANNABISBIT</strong> IST
                                <br/>
                                REVOLUTION.
                            </h1>

                        </div>
                    </div>
                </div>

            </div>
        </header>
    )
}

