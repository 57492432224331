import React  from "react";
import {Helmet} from "react-helmet";
import {Row, Col} from "antd";
import { FastBackwardOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
import {Header, FooterGreenStrip} from "../components";
import "../styles/global.scss";
import siteTitle from "../constants";

function CannabisDetails3() {

  const pageTitle="CannabisBIT / Kryptowährung";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>

      <div className="title_of_header">
        <Header title={pageTitle} />
      </div>

    <div className="relative background-container py-16">

      <section className="mt-8 py-8 px-4 p-font-big" style={{maxWidth: '1200px'}}>
        <Row align="middle">
          <Col md={12} className="px-6">
            <img src="/cannabisdetails3-1.jpeg" alt="CANNABISBIT KRYPTOWÄHRUNG" />
          </Col>
          <Col md={12} className="py-4 px-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
            <h2>CANNABISBIT KRYPTOWÄHRUNG</h2>
            <p>Kryptowährung ist für viele Menschen etwas Ungreifbares und Unbegreifliches. Das wollen wir ändern. Mit unserem transparenten Zahlsystem wird das Bezahlen kinderleicht.  Die CannabisBIT hat für den Kauf von legalisiertem Cannabis ein offenes Bezahlsystem entwickelt, um den Zugang zu diesem wertvollen Produkt so einfach wie möglich zu gestalten. Sei es zum Genuss oder zu medizinischen Zwecken. </p>
            </AnimationOnScroll>
          </Col> 
        </Row>
      </section>

      <section className="mt-16 py-8 px-4 p-font-big" style={{maxWidth: '1200px'}}>
        <Row align="middle">
          <Col md={12} className="py-4 px-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
            <p>Mit unserer Kryptowährung wird es zukünftig möglich sein, in unserem Onlineshop www.jointstore.de einkaufen zu gehen. Neben den üblichen Zahlungsmitteln wie Überweisung, Paypal oder Klarna, wird  es möglich sein, mit dem CannabisBIT bezahlen zu können. Der Vorteil daran ist,  dass es mit dem Einsatz der Kryptowährung mehr fürs gleiche Geld gibt: Mehr Ware oder einen günstigeren Einkauf. Ebenfalls ist in Planung, CannabisBIT ab einer bestimmten Einkaufshöhe dem Kunden, im Rahmen eines Punktesystems, als Gegenwert zum Dank zurück zu geben.</p>
            </AnimationOnScroll>
          </Col> 
          <Col md={12} className="px-6">
            <img src="/cannabisdetails3-2.jpeg" alt="" />
          </Col>
        </Row>
      </section>

      <section className="mt-16 py-8 px-4 p-font-big" style={{maxWidth: '1200px'}}>
        <Row align="middle">
          <Col md={12} className="px-6">
            <img src="/cannabisdetails3-3.jpeg" alt="SICHERHEITSSTANDARTS" />
          </Col>
          <Col md={12} className="py-4 px-4 md:px-8">
            <AnimationOnScroll animateIn="animate__slideInRight">
            <h2>SICHERHEITSSTANDARTS</h2>
            <p>Wenn wir Ihre Kaufabsicht mit unserem System verbinden, ist der CannabisBIT Ihr persönlicher Qualitäts- und Legitimitätsnachweis und zeichnet die Herkunft eines Produkts und dessen Erwerb auf. Somit können Sie Ihren Kauf jederzeit nachweisen und geraten nicht in Verdacht, etwas Illegales getan zu haben. Wir garantieren bereits jetzt die strengen Regeln der DS-GVO nach deutschen Sicherheitsstandards einzuhalten. Ihre Daten sind bei uns sicher und sind gleichzeitig ein Sicherheitsgarant für Sie.</p>
            </AnimationOnScroll>
          </Col> 
        </Row>
        <NavLink to="/" className="home-link relative top-5"><FastBackwardOutlined /> Zurück</NavLink>
      </section>

    </div>
    <FooterGreenStrip stripText="WIR BIETEN DIE LÖSUNG FÜR MEHR GRÜN" />
    </>
  );
}

export default CannabisDetails3;