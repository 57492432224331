import React from "react";
import {Helmet} from "react-helmet";
import {Header} from "../components";
import "../styles/global.scss";
import siteTitle from "../constants";

function AGB() {

  const pageTitle="AGB CannabisBit";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>
    <Header title={pageTitle} />
    <div className="relative background-container py-16 mb-8 px-4 lg:px-24">

      <section className="mt-8 py-8 px-4 lg:px-32 lg:py-24 p-font-big">
         
         <div className="part-1">

          <h2>AGB CANNABISBIT</h2>

          <p><strong>Haftung für Inhalte</strong></p>

          <p>Die auf dieser Webseite abrufbaren Beiträge dienen nur der allgemeinen Information und nicht der Beratung in konkreten Fällen. Wir sind bemüht, für die Richtigkeit und Aktualität aller auf der Website enthaltenen Informationen und Daten gemäß § 7 Abs.1 TMG zu sorgen. Für die Korrektheit, die Vollständigkeit, die Aktualität oder Qualität der bereitgestellten Informationen und Daten wird jedoch keine Gewähr nach § 8 bis 10 TMG übernommen. Die Haftung für den Inhalt der abrufbaren Informationen wird ausgeschlossen, soweit es sich nicht um vorsätzliche oder grob fahrlässige Falschinformation handelt. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>

          <p><strong>Haftung für Links</strong></p>

          <p>Wir sind für den Inhalt von Webseiten, die über einen Hyperlink erreicht werden, nicht verantwortlich. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Wir machen uns die Inhalte dieser Internetseiten ausdrücklich nicht zu eigen und können deshalb für die inhaltliche Korrektheit, Vollständigkeit und Verfügbarkeit keine Gewähr leisten. Wir haben bei der erstmaligen Verknüpfung zwar den fremden Inhalt daraufhin überprüft, ob durch ihn eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird. Wir sind aber nicht dazu verpflichtet, die Inhalte, auf die wir unserem Angebot verweisen, ständig auf Veränderungen zu überprüfen, die eine Verantwortlichkeit neu begründen könnten. Erst wenn wir feststellen oder von anderen darauf hingewiesen werden, dass ein konkretes Angebot, zu dem wir einen Link bereitgestellt haben, eine zivil- oder strafrechtliche Verantwortlichkeit auslöst, werden wir den Verweis auf dieses Angebot aufheben, soweit uns dies technisch möglich und zumutbar ist.</p>

          <p><strong>Urheberrecht</strong></p>

          <p>Die durch den Betreiber dieser Seite erstellten Inhalte und Werke auf diesen Webseiten unterliegen dem deutschen Urheberrecht. Sämtliche Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Kopien von diesen Seiten sind nur für den privaten Bereich gestattet, nicht jedoch für kommerzielle Zwecke. Alle hier gezeigten Inhalte, gleich welcher Art, sind geistiges Eigentum der CannabisBIT GmbH und gehören den Betreibern.</p>

          <p><strong>Datenschutz</strong></p>

          <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail- Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. In Verbindung mit Ihrem Zugriff werden in unserem Server für Zwecke der Datensicherheit vorübergehend Daten gespeichert, die möglicherweise eine Identifizierung zulassen (zum Beispiel IP-Adresse, Datum, Uhrzeit und Name der angeforderten Datei). Eine Auswertung, mit Ausnahme für statistische Zwecke in anonymisierter Form, erfolgt nicht. Sollten Sie bei uns Informationsmaterialien bestellen oder Kryptowährung kaufen wollen, benötigen wir von Ihnen Name, E-Mail-Adresse sowie Angaben zur Lieferung und Rechnungsstellung. So können wir Ihre Bestellung bearbeiten und ausführen. Diese Daten werden für die Dauer der Auftragsabwicklung in elektronischer Form gespeichert. Eine weitergehende Nutzung personenbezogener Daten oder eine Weitergabe an Dritte erfolgt ohne Ihre ausdrückliche Einwilligung nicht. Mit der Bestellung willigen Sie in die genannte Verarbeitung Ihrer personenbezogenen Daten ein, soweit dies für den Zweck des Vertrages erforderlich ist. Sie können diese Einwilligung schriftlich oder per E-Mail uns gegenüber jederzeit mit Wirkung für die Zukunft widerrufen. Im Weiteren gilt die DS-GVO, die Sie hier nachlesen können.</p>

          <p><strong>Kryptowährung</strong></p>

          <p>Die im Zuge des Internetangebots angebotene Kryptowährung unterliegt weitaus größeren AGB. Diese sind zu beachten und hinter der entsprechenden Verlinkung hinterlegt.</p>

         </div>

         <div className="part-2 mt-16">

          <h2>HAFTUNGSAUSSCHLUSS/DISCLAIMER</h2>

          <p><strong>Haftung für Inhalte</strong></p>

          <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>

          <p><strong>Haftung für Links</strong></p>

          <p>Unser Angebot enthält eventuell Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>

          <p><strong>Urheberrecht</strong></p>

          <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. Sollten Sie das CannabisBIT-Logo herunterladen wollen, so können Sie dies ohne Zustimmung. Beim Abdruck oder der öffentlichen weiteren Verwendung ist jedoch immer die Quelle www.CannabisBIT.de zu nennen.</p>

         </div>
        
      </section>

    </div>
    </>
  );
}

export default AGB;