import React  from 'react';
import '../styles/header.scss';


function Navigation() {
  return (
    <div className="navigation pt-5 mobile-menu">
  
    </div>
  )
}

export default Navigation
