import React  from "react";
import {Header} from "../components";
import {Helmet} from "react-helmet";
import { Button } from "antd";
import { HomeFilled } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import "../styles/global.scss";
import siteTitle from "../constants";

function NotFound404() {

  const pageTitle = "Sorry! Page Not Found!";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>
    <Header title={pageTitle} />
    <div className="relative bg-white text-center py-32">
      <h1 className="text-4xl font-light">Page not found</h1>
      <NavLink to="/"><Button className="btn-go-to-homepage mt-8 mx-auto"><HomeFilled className="relative -top-1 -left-2" />Go To Homepage!</Button></NavLink>
    </div>
    </>
  );
}

export default NotFound404;