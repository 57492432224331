import React  from "react";
import {Helmet} from "react-helmet";
import {Header} from "../components";
import "../styles/global.scss";
import siteTitle from "../constants";

function Risikoaufklarung() {

  const pageTitle="Risikoaufklärung";

  return (
    <>
    <Helmet>
      <title>{pageTitle} - {siteTitle}</title>
    </Helmet>
        <div className="title_of_header">
            <Header title={pageTitle} />
        </div>
    <div className="relative background-container py-16 mb-8 px-4 lg:px-24">

      <section className="mt-8 py-8 px-4 lg:px-32 lg:py-24 p-font-big">
         
         <h2>RISIKOAUFKLÄRUNG</h2>

         <p><strong>Allgemeine Risikoaufklärung</strong></p>

         <p>Unser Produkt, der CannabisBIT Token, ist eine (bald) käufliche Kryptowährung. Kryptowährung ist digitales Geld, welches auf bestimmten Plattformen gehandelt werden kann. Dies kann mit einem hohen Risikoverbunden sein und ist ggf. für viele Investoren eventuell nicht geeignet. Dieses Aufklärungsdokument informiert Sie über Risiken, die mit diesen Produkten verknüpft sind, kann aber nicht alle Risiken erklären, auch nicht, wie sich diese Risiken zu Ihren persönlichen Umständen verhalten. Sollten Sie sich unsicher sein, sollten Sie professionellen Rat einholen. Steuerberater oder Bankberater können hier unterstützen. Es ist wichtig, dass Sie die damit verbundenen Risiken voll verstehen, bevor Sie sich dazu entschließen, mit CannabisBIT Token zu traden, und dass Sie über die entsprechenden finanziellen Ressourcen verfügen, um solche Risiken tragen zu können, und dass Sie Ihre Positionen sorgfältig überwachen. Diese Hinweise informieren Sie im Weiteren über die Risiken im Zusammenhang mit Anlageprodukten, in die Sie durch unsere Dienstleistung investieren können, die Ihnen von der CannabisBIT GmbH angeboten werden. Die CannabisBIT GmbH bietet eine Kryptowährung in Form einer Subcoin an. Zu dem von der CannabisBIT GmbH angebotenen Investmentprodukt gehört der Token, die digitale Währung, die Kryptowährung, an denen Sie Besitz erwerben</p>

         <p><strong>Chancen</strong></p>

         <p>Die CannabisBIT GmbH bietet Investoren die Möglichkeit, Kryptowährung zu kaufen, um solche Vermögenswerte zu halten und/oder anschließend wieder zu verkaufen. Alle Transaktionen im Zusammenhang mit Kryptowährungen unterliegen bestimmten Bedingungen für das Trading von Kryptowährungen.</p>

         <p><strong>Kontrolle</strong></p>

         <p>Da die Märkte für Kryptowährungen dezentralisiert sind und nicht der Aufsicht unterliegen, handelt es sich bei dem Begriff Trading-Services bei Kryptowährungen, wie er in dem Zusatz für das Trading von Kryptowährungen definiert ist, um nicht der Aufsicht unterliegende Services, die von keiner der europäischen Aufsichtsbehörden kontrolliert werden (auch nicht vom MIFID). Deshalb kommen Kunden der CannabisBIT GmbH nicht in den Genuss des entsprechenden Schutzes. Wir werden uns bemühen, Sie in den Genuss der Vorteile der Regeln in Bezug auf beste Ausführung und Verwahrung der Vermögenswerte von Kunden kommen zu lassen.</p>

         <p><strong>Gefahrenhinweis</strong></p>

         <p>Trading birgt Gefahren für Ihr eingesetztes Kapital. Sie sollten kein Geld investieren, das Sie sich nicht leisten könnten zu verlieren; Sie können jedoch nicht mehr verlieren als das Kapital auf Ihrem Konto.</p>

         <p><strong>Beurteilung</strong></p>

         <p>Bevor Sie den CannabisBIT Token kaufen, sind wir verpflichtet zu beurteilen, ob das Produkt / die Dienstleistung, oder die Produkte / die Dienstleistungen, das/die Sie ausgewählt haben, für Sie angemessen sind, und Sie zu warnen, wenn ein Produkt oder eine Dienstleistung auf Basis der von Ihnen bereitgestellten Informationen nicht angemessen sein sollte. Wenn Sie sich dazu entschließen, dennoch fortzufahren, bestätigen Sie damit, dass Sie sich des Risikos bewusst sind und es verstehen.</p>

         <p><strong>Positionsüberwachung</strong></p>

         <p>Sie sollten darüber hinaus gewährleisten, dass Sie in der Lage sind, Positionen auf Ihrem Konto jederzeit zu überwachen, denn die Verantwortung dafür liegt allein bei Ihnen. Wir sind für die Überwachung der Positionen auf Ihrem Konto nicht verantwortlich.</p>

         <p><strong>Ausführung</strong></p>

         <p>Es ist jederzeit möglich, dass sich der Kurs des CannabisBIT Token zwischen Auftragserteilung und Ausführungszeit ändert. Wir können deshalb nicht garantieren, dass die Ausführung zu demselben Kurs erfolgt, den Sie verlangt hatten, dabei kann der Kurs, den Sie erhalten, zu Ihren Gunsten oder zu Ihren Ungunsten ausfallen. Die Verantwortung dabei liegt ausschließlich bei Ihnen. Wir sind nur der Anbieter der CannabisBIT Coin, dem Token und sind nicht für seine Entwicklung verantwortlich. Es gelten im Weiteren die Bedingungen der entsprechenden Handelsplattform. Darüber hinaus bestehen Risiken im Zusammenhang mit der Verwendung von Online-Ausführung und -Tradingsystemen, darunter beispielsweise Software- oder Hardwarefehler und die Internetverbindung.</p>

         <p><strong>Trading-Risiken</strong></p>

         <p>Da die Märkte für Kryptowährungen dezentralisiert sind und nicht der Aufsicht unterliegen, handelt es sich bei unseren Trading-Services bei Kryptowährungen um nicht der Aufsicht unterliegende Services, die von keiner der europäischen Aufsichtsbehörden kontrolliert werden (auch nicht vom MIFID). Das bedeutet, dass es keine Zentralbank gibt, die korrigierende Maßnahmen einleiten kann, um</p>

         <p><strong>WICHTIG</strong></p>

         <p>KRYPTOWÄHRUNGSMÄRKTE UNTERLIEGEN AUSSCHLIESSLICH DEN GESETZEN VON ANGEBOT UND NACHFRAGE.</p>

         <p><strong>Grundsätzliches</strong></p>

         <p>Der Markt für Kryptowährungen ist sehr dynamisch, und die jeweiligen Preise sind häufig schwer vorhersehbar und äußerst volatil. Die Preise von Kryptowährungen sind gewöhnlich nicht transparent und hoch spekulativ und sind anfällig für Marktmanipulation. Im schlimmsten Fall kann es sein, dass das Produkt wertlos wird. Es ist wichtig, zwischen Richtpreisen, die auf Charts angezeigt werden, und handelbaren Preisen, die auf der entsprechenden Trading-Plattform angezeigt werden, zu unterscheiden. Richtpreise zeigen lediglich an, wo der Markt steht. Da die Märkte für Kryptowährungen dezentral sind, d. h. dass es keine einzelne zentrale Börse gibt, wo alle Transaktionen durchgeführt werden, kann jeder Market-Maker leicht abweichende Preise anbieten. Deshalb stellen alle Preise „Richtpreise“ dar und geben nicht unbedingt die tatsächlichen „Handelspreise“ wieder, zu denen Transaktionen durchgeführt werden können. Das Trading mit Kryptowährungen ist wegen der Anonymität der Transaktionen anfällig für Missbrauch zu illegalen Aktivitäten und auf Investoren könnte es sich negativ auswirken, wenn Strafverfolgungsbehörden angeblich illegale Aktivitäten untersuchen.</p>

         <p><strong>Ausschlusserklärung – Rechtssicherheit</strong></p>

         <p>Die CannabisBIT GmbH unternimmt zu keiner Zeit rechtswidrige Geschäfte, zu keiner Zeit einen illegalen Handel, Vertrieb und/oder Anbau mit Cannabis. Die Gesamtlage zu Cannabis, dessen Konsum, der Handel, der Vertrieb und der Versand unterliegen strengen Bestimmungen, die wir zu jederzeit mit der Bundesopiumstelle, dem Zoll und anderen staatlichen Institutionen besprechen, rückkoppeln und, wenn nötig, freigeben lassen. Ziel aller Unternehmungen unsererseits ist das dauerhafte Bestreben auf der rechtlich korrekten Seite zu stehen.</p>

         <p><strong>Erweiterte Informationen</strong></p>

         <p>Bestimmte Kryptoassets können zusätzliche oder spezifische Risiken bergen. Neu ausgegebene Kryptowährungen können zusätzliche Risiken bergen, die Sie berücksichtigen müssen; dazu zählen begrenzte Liquidität oder Schwierigkeiten beim Handel mit dem Vermögenswert nach dessen Erwerb. Dies bedeutet, dass die Preise eventuell volatil sind, d. h. schnell steigen und fallen, und die Liquidität begrenzt sein kann – alles abhängig von Angebot und Nachfrage. Der CannabisBIT GmbH ist es nicht möglich, diese externen Faktoren zu kontrollieren. Die CannabisBIT GmbH kann nicht für etwaige marktbedingte Schwankungen verantwortlich gemacht werden.</p>

         <p><strong>Blockchain-Risiken</strong></p>

         <p>Da es sich bei der Blockchain um ein unabhängiges direktes Netzwerk handelt, das durch die CannabisBIT GmbH in keinster Weise kontrolliert wird, ist für die CannabisBIT GmbH für kein Versagen und/oder keinen Fehler und/oder keinen Verstoß verantwortlich, das/der sich in der Blockchain oder in einem sonstigen Netzwerk, in dem die Kryptowährungen ausgegeben und/oder getradet werden, ergeben kann. Sie sind durch jegliche Änderung und/oder Ergänzung des Blockchainsystems gebunden und unterliegen allen Gesetzen, die für die Blockchain eventuell gelten. Wir geben – weder hinsichtlich der Funktionalität der Blockchain noch hinsichtlich eventueller Sicherheitsverstöße in der Blockchain – keinerlei Zusicherungen oder Garantien jedweder Art, weder ausdrücklich noch implizit, weder gesetzlicher noch sonstiger Art.</p>

         <p><strong>Führung von Kryptowährungsprotokollen</strong></p>

         <p>Die CannabisBIT GmbH ist nicht im Besitz der zugrunde liegenden Softwareprotokolle oder kontrolliert diese nicht, welche die Funktionsweise von Kryptowährungen regeln, die zum Handel zur Verfügung stehen. Grundsätzlich sind die zugrunde liegenden Protokolle Open-Source- Systeme, und jeder kann diese nutzen, kopieren, ändern und vertreiben. Die CannabisBIT GmbH gibt keinerlei Garantie zu deren Funktionsweise, Sicherheit oder Verfügbarkeit ab. Die zugrundeliegenden Protokolle unterliegen plötzlichen Änderungen der Betriebsvorschriften, sogenannten „Forks“, und diese Forks können den Wert, die Funktion und/oder sogar den Namen der Kryptowährung wesentlich beeinflussen.</p>

         <p><strong>Risiken durch Dritte</strong></p>

         <p>Es kann sein, dass wir uns entscheiden, uns zur Ausführung eines Auftrags oder zum Halten von Zahlungsmitteln und Kryptowährungen Dritter zu bedienen. Bei solchen Dritten handelt es sich nicht um Banken, die ihre Zahlungsmittel / virtuellen Währungen als Einzahlung halten. Sollte ein solcher Dritter Geld verlieren, zahlungsunfähig werden oder sein Geschäft aufgeben, gibt es keinen bestimmten rechtlichen Schutz, der Sie vor Verlust des bei diesem Dritten verwahrten Geldes schützt, selbst wenn es sich bei diesem Dritten um ein bei einer Landesbehörde registriertes Unternehmen handelt. Je nach der Struktur und Sicherheit der digitalen Geldbörse sind einige anfällig für Hackerangriffe, was zum Diebstahl der virtuellen Währung oder der Vermögenswerte des Kunden führen kann. Die CannabisBIT GmbH ist für einen durch solche Dritte verursachten Verlust nicht verantwortlich.</p>

         <p><strong>Delisting und/oder nicht unterstützte Kryptowährungen</strong></p>

         <p>Wenn zu einem Zeitpunkt die Kryptowährungen, die Gegenstand Ihres Auftrags ist, dekotiert werden und/oder wir den Handel in diesen Kryptowährungen gleich aus welchem Grund nicht mehr unterstützen, wird der jeweilige Auftrag sofort geschlossen. Wenn die CannabisBIT GmbH darüber informiert wird, dass unsere Kryptowährung wahrscheinlich dekotiert und/oder bei einer (oder allen) Börse gestrichen und/oder entfernt wird, bemühen wir uns, Ihre Kryptowährung zu diesem Zeitpunkt in Ihrem Namen zu solch einem Preis und auf die Art und Weise zu verkaufen, die die CannabisBIT GmbH bestimmt.</p>

         <p><strong>Automatisiertes Trading und Internetrisiken</strong></p>

         <p>Während Sie auf der Handelsplattform Ihrer Wahl traden, können Systemfehler auftreten. Sie sollten sich der Risiken bewusst sein, die sich aus Systemfehlern ergeben können, was bedeuten könnte, dass Ihre Order verzögert oder gar nicht ausgeführt wird. Die CannabisBIT GmbH kann und wir dazu zu keinem Zeitpunkt haftbar gemacht werden. Sie bestätigen, dass mit der Nutzung eines internetbasierten Trading- Systems Risiken verbunden sind, insbesondere durch den Ausfall von Hardware, Software oder Internetverbindungen; das Risiko böswilliger Softwareangriffe; das Risiko, dass Dritte sich unbefugt Zugang zu Informationen und/oder Vermögenswerten verschaffen (auch der Kryptowährungen), die in Ihrem Namen verwahrt werden; Cyberangriffe; den Ausfall des Kryptowährungsnetzwerks (wie die Blockchain); Computerviren; Kommunikationsversagen, Störungen, Fehler, Verzerrungen oder Verzögerungen. Die Sie beim Trading über die Services möglicherweise, aus welchen Gründen auch immer, erleben; Spyware, Scareware, trojanische Pferde, Würmer oder sonstige Schadsoftware, die sich auf Ihren Computer oder sonstige Ausrüstung auswirken, sowie durch eventuelles Phishing, Spoofing oder einen sonstigen Angriff. Sie sollten sich auch bewusst sein, dass SMS- und E-Mail-Dienste anfällig für Spoofing- und Phishing-Angriffe sind und Sie sollten Vorsicht walten lassen, wenn Sie Mitteilungen überprüfen, die angeblich von der CannabisBIT GmbH stammen sollen.</p>

         <p><strong>Gebühren und Kosten</strong></p>

         <p>Seien Sie sich bitte aller Kosten und Gebühren bewusst, die bei Ihnen anfallen können, weil solche Kosten und Gebühren sich auf Ihre Rentabilität auswirken.</p>

         <p><strong>Informationen</strong></p>

         <p>Alle Meinungen, Nachrichten, Forschungsergebnisse, Analysen, Kurse oder sonstigen auf dieser Website enthaltenen Informationen werden als allgemeine Marktkommentare bereitgestellt. Sie stellen keine Anlageberatung dar. Die CannabisBIT GmbH ist für keinerlei Verluste verantwortlich, die aus einem Investment resultieren, das auf einer Empfehlung, einer Prognose oder einer sonstigen bereitgestellten Information beruht.</p>

         <p><strong>Wertentwicklung in der Vergangenheit</strong></p>

         <p>Eine Wertentwicklung in der Vergangenheit lässt nicht auf zukünftige Ergebnisse schließen. Der Wert einer Investition kann sowohl fallen als auch steigen.</p>

         <p><strong>Währungsrisiko</strong></p>

         <p>Ihr Konto bei der CannabisBIT GmbH wird in USD geführt, was sich von der Währung unterscheiden kann, in der Sie Ihre Einzahlungen geleistet haben. Entsprechend sollten Sie sich der Fluktuation von Wechselkursen bewusst sein. Achten Sie dabei auf mögliche Schwankungen, die aus diesen Wechselkursen entstehen. Die CannabisBIT GmbH kann für die Wechselkursschwankungen nicht haftbar gemacht werden.</p>

         <p><strong>Gültigkeit</strong></p>

         <p>Diese Hinweise sind ab dem Zeitpunkt gültig, alsbald der Token auf den Tradingplattformen käuflich erwerblich ist.<br />
         <em>CannabisBIT GmbH Stand, 25.04.</em></p>
        
      </section>

    </div>
    </>
  );
}

export default Risikoaufklarung;